import humps from 'humps'

import type { CheckedNodes } from '@/javascript/vuejs/components/shared/TreeView'

import type { ApiClient } from '../api'
import {
	getPackageFolderList,
	getPackageFolders,
	getPackages,
	putMovePackageAmongFolder,
} from '../apiRoutes'
import type {
	ContentPackage,
	ContentPackageFolder,
	Package,
	Packages,
	PackageType,
} from './interfaces'

export async function createFolder(api: ApiClient, { name, parent_content_package_folder_id }:
	{ name: string, parent_content_package_folder_id: number | null }): Promise<void> {
	const payload = {
		package_folder: {
			name,
			parent_content_package_folder_id,
		},
	}

	await api.post(getPackageFolders, payload)
}

export async function deleteContentPackageFolders(api: ApiClient, folderIds: number[]): Promise<void> {
	await api.delete(getPackageFolders, { ids: folderIds })
}

export async function getContentPackageFolderList(api: ApiClient): Promise<ContentPackageFolder[]> {
	const { data } = await api.get<ApiContentPackageFolderListResponse>(getPackageFolderList)

	return data
}

export async function getContentPackageFolders(api: ApiClient, { include_all, parent_folder_id }:
	{ include_all?: boolean, parent_folder_id?: number }): Promise<ContentPackageFolder[]> {
	const payload = { include_all, parent_folder_id }

	const { data } = await api.get<ApiContentPackageFolderResponse>(getPackageFolders, payload)

	return data
}

export async function getContentPackages(api: ApiClient, options?: Options): Promise<ContentPackage[]> {
	const { data } = await api.get<ApiContentPackagesResponse>(getPackages, {...options})

	return data.map(c => ({
		assignedCourses: c.assigned_courses,
		certifications: c.certifications,
		copyPath: c.copy_path,
		cumulativeCourses: c.cumulative_courses,
		editPath: c.edit_path,
		hours: c.hours,
		id: c.id,
		isBuildingBlock: c.is_building_block,
		name: c.name,
		subPackages: c.sub_packages,
		type: c.type,
	}))
}

export async function getContentPackagesByStateRequirements(api: ApiClient, stateRequirementIds: number[]): Promise<Packages[]> {
	const { data } = await api
		.get<ApiStateRequirementsResponse>(`/training/packages/state_requirements?state_requirement_ids=${stateRequirementIds}`)

	return data.results.map(p => ({
		packageName: p.package_name,
		packages: humps.camelizeKeys(p.packages) as [Package],
	}))
}

export async function moveContentPackageOrFolder(api: ApiClient, { id, items }:
	{ id: number | null, items: CheckedNodes[] }): Promise<void> {
	await api.put(putMovePackageAmongFolder, {
		destination_content_package_folder_id: id,
		items,
	})
}

export async function renameContentPackageFolder(api: ApiClient, { id, name }: { id: number, name: string }): Promise<void> {
	await api.put(`/content_package_folders/${id}`, {package_folder: {name}})
}

export type ApiContentPackageFolderListResponse = ContentPackageFolder[]

export type ApiContentPackageFolderResponse = ContentPackageFolder[]

export type ApiContentPackagesResponse = ApiContentPackage[]

export interface ApiStateRequirementsResponse { results: ApiPackages[] }

export interface ApiContentPackage {
	assigned_courses: number
	certifications: number
	copy_path: string
	cumulative_courses: number
	edit_path: string
	hours: string | null
	id: number
	is_building_block: boolean
	name: string
	sub_packages: string
	type: 'content_package'
}

export interface ApiContentPackageFolder {
	children?: ContentPackageFolder[]
	id: number
	level: number
	name: string
	parent_folder_id?: number | string | undefined
	type: PackageType
}

export interface ApiPackages {
	package_name: string
	packages: Package[]
}

export interface FolderPackageOptions {
	id: number | string | undefined
	name?: string
	type: PackageType | undefined
}

export interface Options {
	direction?: 'asc' | 'desc'
	format: 'json'
	is_building_block: boolean | undefined
	search?: string
	sort?: string
}

export interface Results {
	results: ApiPackages
}

export type PackageOptions = 'folder' | 'package'
